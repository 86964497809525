import React, { useEffect, useState } from 'react';
import Page from '../sections/Page';
import { Button, Result } from 'antd';
import styled from 'styled-components';
import Loading from '../components/Loading';
import { useQuery } from '../hooks/useQuery';
import useValidateToken from '../hooks/useValidateToken';
import { Link } from 'react-router-dom';


export default function EmailConfirmation() {
  const [status, setStatus] = useState<boolean | null>(null);
  const query = useQuery();
  const { success, validate } = useValidateToken();

  useEffect(() => {
    const token = query.get('token');
    if (!token || token === '') {
      setStatus(false);
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    validate(token);
  }, [query, validate]);

  useEffect(() => {
    setStatus(success);
  }, [success]);


  return (
    <Page
      showHeader={false}
      background='#001529'
      child={
        <Container>
          {
            status == null ?
              <Loading /> :
              <Result
                status={status ? 'success' : 'error'}
                title={status ? 'Succesfully Validated' : 'Oops!'}
                subTitle={
                  status ? 'Your email was validated. Please log in to start using our services.'
                    : 'Something wrong occurred while trying to validate your email. Please try again later!'
                }
                extra={status ? [
                  <Link to='/login'>
                    <StyledButton type='primary'>
                      Log In
                    </StyledButton>
                  </Link>,
                ] : []}
              />
          }
        </Container>
      }
    />
  );
}

const Container = styled.div`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  max-width: 70rem;
  box-shadow: 1rem 1rem .5rem 0 #dedede;
  border-radius: 2rem;
  padding: 2rem;
  background-color: #FFFFFF;
`;

const StyledButton = styled(Button)`
  width: 100%;

  &.ant-btn-primary {
    background-color: #001529;
    color: #FFFFFF;
    border-color: #001529;
  }
`;