import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../hooks/UserContext';
import Page from '../sections/Page';

export default function Landing() {
  const { user } = useContext(UserContext);
  if (!!user) {
    return <Redirect to='/home' />;
  }

  return (
    <Page
      showHeader={true}
      child={
        <h3>Welcome to the BroadbandAnalyzer Portal</h3>
      } />
  );
}