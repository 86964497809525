import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAuth from './../hooks/useAuth';
import Page from '../sections/Page';
import styled from 'styled-components';
import { Alert, Button, Form, Input } from 'antd';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const emailNotConfirmedError = 'Email is already registered but has not been verified, click on a button on the right to send new verification email.';

export default function Login() {
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [formValues, setFormValues] = useState<any>(null);
  const { loginUser, error, sentVerification } = useAuth();

  const history = useHistory();

  const routeChangeToRegisterPage = () => {
    const path = '/register'; 

    history.push(path);
  }

  const routeChangeToRecoverPasswordPage = () => {
    const path = '/recover-password';

    history.push(path);
  }

  useEffect(() => {
    if (!error) {
      setErrorMessage(null);
      return;
    }
    setErrorMessage(error?.message ?? 'Oops! Some error has ocurred!');
  }, [error]);

  return (
    <Page
      showHeader={false}
      styles={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: '60px 15px',
      }}
      child={
        <>
          <Header />
          <Container>
            <Link to={'/'}>
              <Title>Broadband Analyzer</Title>
              <Subtitle>Login</Subtitle>
            </Link>
            <StyledForm
              {...layout}
              name='login'
              onFinish={(values) => {
                setFormValues(values);
                loginUser(values);
              }}
            >
              <Form.Item
                label='E-mail'
                name='email'
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <StyledInput variant="borderless" />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <StyledPassword variant="borderless" />
              </Form.Item>

              <Form.Item wrapperCol={{
                xs: {
                  offset: 0,
                  span: 20,
                },
                sm: {
                  offset: 4,
                  span: 20,
                },
              }}>
                <StyledButton type='primary' htmlType='submit' shape='round'>
                  Log In
                </StyledButton>
                <TextDivider>Or</TextDivider>
                <ButtonsHolder>
                  <StyledAdditionalButton onClick={routeChangeToRegisterPage}>Register now</StyledAdditionalButton>
                  <StyledAdditionalButton onClick={routeChangeToRecoverPasswordPage}>Forgot Password?</StyledAdditionalButton>
                </ButtonsHolder>
              </Form.Item>
              {
                !!errorMessage &&
                <Alert
                  message={errorMessage}
                  type='error'
                  closable
                  onClose={() => setErrorMessage(null)}
                  action={
                    errorMessage === emailNotConfirmedError && formValues?.email && (
                      <Button
                        size="small"
                        type="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          sentVerification(formValues?.email);
                          setFormValues(null);
                          setErrorMessage(null);
                        }}
                      >
                        Send verification
                      </Button>
                    )
                  }
                />
              }
            </StyledForm>
          </Container>
        </>
      } />
  );
}

const Container = styled.div`
  width: 50%;
  max-width: 70rem;
  box-shadow: 1rem 1rem .5rem 0 #dedede;
  border-radius: 2rem;
  padding: 2rem;
  background-color: #FFFFFF;
  @media screen and (max-width: 1200px) {
    max-width: 100%;
    width: 80%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const Header = styled.div`
  background-color: #001529;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
`;

const Title = styled.div`
  color: #001529;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: .5rem;
  width: 100%;
  text-align: center;
`;

const NotificationVerification = styled.div`
  padding: 1rem;
  text-align: center;
`;

const Subtitle = styled(Title)`
  font-size: 1.4rem;
`;

const StyledButton = styled(Button)`
  width: 100%;

  &.ant-btn-primary {
    background-color: #001529;
    color: #FFFFFF;
    border-color: #001529;
  }
`;

const TextDivider = styled.div`
  padding: 15px 0 0;
  text-align: center;
`;

const ButtonsHolder = styled.div`
  text-align: center;
`;

const StyledAdditionalButton = styled(Button)`
  margin: 3px;
`;

const StyledForm = styled(Form)`
  margin-top: 3rem;
`;
const StyledInput = styled(Input)`
  border-bottom: 1px #001529 solid;

  &:hover {
    border-bottom: 1px #001529 solid;
  }
`;

const StyledPassword = styled(Input.Password)`
  border-bottom: 1px #001529 solid;

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-bottom: 1px #001529 solid;
  }
`;
