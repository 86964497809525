import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAuth from './../hooks/useAuth';
import Page from '../sections/Page';
import { Alert, Button, Form, Input } from 'antd';
import styled from 'styled-components';

const layout = {
  // labelCol: { span: 4 },
  // wrapperCol: { span: 20 },
};

export default function Register() {
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const { registerUser, error } = useAuth();

  const history = useHistory(); 

  const routeChangeToLoginPage = () =>{ 
    const path = '/login'; 

    history.push(path);
  }

  useEffect(() => {
    if (!error) {
      setErrorMessage(null);
      return;
    }
    setErrorMessage(error?.message ?? 'Oops! Some error has ocurred!');
  }, [error]);


  return (
    <Page
      showHeader={false}
      styles={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: '60px 15px',
      }}
      child={
        <>
          <Header />
          <Container>
            <Link to={'/'}>
              <Title>Broadband Analyzer</Title>
              <Subtitle>New Account</Subtitle>
            </Link>
            <StyledForm
              {...layout}
              name='login'
              layout="vertical"
              onFinish={(values: any) => registerUser(values)}
            >
              <Form.Item
                label='Name'
                name='username'
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <StyledInput variant="borderless" />
              </Form.Item>

              <Form.Item
                label='E-mail'
                name='email'
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <StyledInput variant="borderless" />
              </Form.Item>

              <Form.Item
                label='County or Community'
                name='county'
                rules={[{ required: true, message: 'Please choose your county or community!' }]}
              >
                <StyledInput variant="borderless" />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <StyledPassword variant="borderless" />
              </Form.Item>

              <Form.Item wrapperCol={{
                // offset: 4, span: 20
              }}>
                <StyledButton type='primary' htmlType='submit' shape='round'>
                  Register
                </StyledButton>
                <TextDivider>Existing account?</TextDivider>
                <ButtonsHolder>
                  <StyledAdditionalButton onClick={routeChangeToLoginPage}>Log in.</StyledAdditionalButton>
                </ButtonsHolder>
                 {/* <a href='/login'>Log in.</a> */}
              </Form.Item>
              {
                !!errorMessage &&
                <Alert message={errorMessage} type='error' closable onClose={() => setErrorMessage(null)} />
              }
            </StyledForm>
          </Container>
        </>
      } />
  );
}

const Container = styled.div`
  width: 50%;
  max-width: 70rem;
  box-shadow: 1rem 1rem .5rem 0 #dedede;
  border-radius: 2rem;
  padding: 2rem;
  background-color: #FFFFFF;
  @media screen and (max-width: 1200px) {
    max-width: 100%;
    width: 80%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const Header = styled.div`
  background-color: #001529;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
`;

const Title = styled.div`
  color: #001529;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: .5rem;
  width: 100%;
  text-align: center;
`;

const TextDivider = styled.div`
  padding: 15px 0 0;
  text-align: center;
`;

const Subtitle = styled(Title)`
  font-size: 1.4rem;
`


const StyledButton = styled(Button)`
  width: 100%;

  &.ant-btn-primary {
    background-color: #001529;
    color: #FFFFFF;
    border-color: #001529;
  }
`;

const ButtonsHolder = styled.div`
  text-align: center;
`;

const StyledAdditionalButton = styled(Button)`
  margin: 3px;
`;

const StyledForm = styled(Form)`
  margin-top: 3rem;
`;
const StyledInput = styled(Input)`
  border-bottom: 1px #001529 solid;

  &:hover {
    border-bottom: 1px #001529 solid;
  }
`;

const StyledPassword = styled(Input.Password)`
  border-bottom: 1px #001529 solid;

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-bottom: 1px #001529 solid;
  }
`;
