import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';
import { User } from '../models/User';
import { notification } from 'antd';

export default function useAuth() {
  let history = useHistory();
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState<{ [prop: string]: any; } | null>(null);

  //set user
  const setUserContext = async () => {
    return await axios.get<User>('/api/auth/current-user').then(res => {
      setUser(res.data);
      history.push('/home');
    }).catch((err) => {
      setError(err.response.data?.error);
    });
  };

  //register user
  const registerUser = async (data: any) => {
    const { username, email, password, county, passwordConfirm } = data;
    return axios.post(`/api/auth/register`, {
      username,
      email,
      password,
      county,
      passwordConfirm,
    }).then(async () => {
      notification.success({
          message: 'Registration Sucessfully!',
          description:
            'Please, go to your inbox to confirm your email before logging in. If you do not see the email in your Inbox, please check your SPAM folder.',
        },
      );
      history.push('/login');
    })
      .catch((err) => {
        setError(err.response.data?.error);
      });
  };

  const recoverPassword = async (data: any) => {
      const { email } = data;
      return axios.post(`/api/auth/recover-password-confirm-email`, {
        email,
      }).then(async () => {
        notification.success({
            message: 'Password Recovery Email Sent',
            description:
              'If we find your email in our database, you will receive an email with a link to reset your password.',
          },
        );
        history.push('/login');
      })
        .catch((err) => {
          setError(err.response.data?.error);
        });
    }
  ;

  const createNewPassword = async (data: {
    email: string;
    password: string;
    token: string;
  }) => {
    return axios.post(`/api/auth/create-new-password`, data).then(async () => {
      notification.success({
        message: 'New Password Created!',
        description: 'New password was created. Now you can log in with a new password',
      });
      history.push('/login');
    })
    .catch((err) => {
      setError(err.response.data?.error);
    });
  }

  //login user
  const loginUser = async (data: any) => {
    const { email, password } = data;
    return axios.post('/api/auth/login', {
      email,
      password,
    }).then(async () => {
      await setUserContext();
    }).catch((err) => {
      setError(err.response.data?.error);
    });
  };

  const sentVerification = async (email: string) => {
    return axios.post(`/api/auth/sent-verification`, { email }).then(async () => {
      notification.success({
        message: 'Verification was send to email!',
        description: 'Check your email please.',
      });
      history.push('/login');
    })
    .catch((err) => {
      setError(err.response.data?.error);
    });
  }

  return {
    registerUser,
    loginUser,
    recoverPassword,
    createNewPassword,
    error,
    sentVerification,
  };
};
