import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import styled from 'styled-components';

interface IPageProps {
  child: JSX.Element;
  showHeader?: boolean;
  background?: string;
  styles?: object;
}

export default function Page(props: IPageProps): JSX.Element {
  const styles = props.styles ?? {};

  return (
    <StyledLayout>
      {
        !!props.showHeader && <Header />
      }
      <StyledContent background={props.background} styles={styles}>
        {props.child}
      </StyledContent>
    </StyledLayout>
  );
}

const StyledLayout = styled(Layout)``;

const StyledContent = styled(Layout.Content)<{ styles: object; background?: string }>`
  padding: 2.5rem;
  background-color: ${(props: any) => props.background ?? '#FFFFFF'};
  ${(props: any) => props.styles ?? {}},
`;