import React from 'react';
import './App.css';
import useFindUser from './hooks/useFindUser';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserContext } from './hooks/UserContext';
import Landing from './pages/Landing';
import Register from './pages/Register';
import Login from './pages/Login';
import RecoverPassword from './pages/RecoverPassword';
import EmailConfirmation from './pages/EmailConfirmation';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import PrivateRoute from './pages/PrivateRoute';
import CreateNewPassword from './pages/CreateNewPassword';

function App() {
  const {
    user,
    setUser,
    isLoading,
  } = useFindUser();

  return (
    <Router>
      <UserContext.Provider value={{ user, setUser, isLoading }}>
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route path='/login' component={Login} />
          <Route path='/register' component={Register} />
          <Route path='/recover-password' component={RecoverPassword} />
          <Route path='/create-new-password' component={CreateNewPassword} />
          <Route path='/account/confirm' component={EmailConfirmation} />
          <PrivateRoute path='/home' component={Home} />
          <Route component={NotFound} />
        </Switch>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
