import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, List, message, Modal, notification, Select, Table, TableProps, Typography, Upload } from 'antd';
import axios from 'axios';

import Page from '../sections/Page';
import { DeleteFilled, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { UserContext } from '../hooks/UserContext';

const DEFAULT_USER_VALUE = 0;
const CHUNK_SIZE = 1024 * 1024 * 30;
const MESSAGE_FILE_EXIST = 'File already exists';

const getRequest = async (cb: (files: any) => void) => {
  axios.get("/api/files")
    .then(function (response) {
      if(response?.data) { 
        cb(response?.data);
      }
    });  
}

const getAdminRequest = async (cb: (files: any) => void, selectedUserID: number) => {
  axios.get("/api/files/admin")
    .then(function (response: {
      data: Array<{ userId: string; }>
    }) {
      if(response?.data) {
        if(selectedUserID === DEFAULT_USER_VALUE) {
          cb(response?.data);
        } else {
          cb(response?.data?.filter(({ userId }) => Number(userId) === selectedUserID));
        }
      }
    });
}

const getUsersByAdminRequest = async (cb: (users: any) => void) => {
  axios.get("api/admin/all/users")
    .then(function (response) {
      if(response?.data) {
        cb(response?.data);
      }
    })
}

interface DataType {
  id: number;
  userId: string;
  name: string;
  uploadCompleted: boolean;
  access: string;
  createdAt: string;
  user: {
    id: number;
    username: string;
    email: string;
    county: string;
    emailVerified: boolean;
    verificationToken: string;
    isAdmin: boolean;
    APPLICATION_URL?: string;
    HOSTING?: string;
  },
  url: Array<any>;
}

export default function Home() {
  const { user } = useContext(UserContext);

  const appURL = user?.APPLICATION_URL || 'http://localhost:3001';
  const isHostingCloud = user?.HOSTING === 'cloud';

  const [files, setFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rewriteFiles, setRewriteFiles]: Array<any> = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUserID, setSelectedUserID] = useState(DEFAULT_USER_VALUE);

  const getUpdatedFiles = () => {
    getRequest(setFiles);

    if(user?.isAdmin) {
      getAdminRequest(setAllFiles, selectedUserID);
    }
  }

  const uploadChunk = async (chunk: any, index: any, fileName: any, totalChunks: any) => {
    const formData = new FormData();
    formData.append('chunk', chunk);
    formData.append('index', index);
    formData.append('filename', fileName);
    formData.append('totalChunks', totalChunks);

    const url = user?.isAdmin && selectedUserID > DEFAULT_USER_VALUE ? `/api/files/admin/${selectedUserID}` : '/api/files';

    await axios.post(url, formData, {})
    .then((response) => {
      const uploadCompleted = response?.data?.response?.uploadCompleted;
      const messageValue = response?.data?.response?.message;

      if(uploadCompleted && messageValue !== MESSAGE_FILE_EXIST) {
        message.success(`${fileName} file uploaded successfully`);
        setTimeout(() => {
          getUpdatedFiles();
        }, 1000)

        setTimeout(() => {
          getUpdatedFiles();
        }, 5000)

        setTimeout(() => {
          getUpdatedFiles();
        }, 10000)
      }

      // Open modal when need to rewrite a file
      if(!uploadCompleted && messageValue === MESSAGE_FILE_EXIST) {
        setRewriteFiles((state: Array<string>) => {
          return [...state, fileName];
        });
        setIsModalOpen(true);
      }
    })
    .catch((err) => {
      message.error(`${fileName} file upload failed.`);
    });
  };

  const props = {
    customRequest: async ({ file, onSuccess, onProgress }: any) => {
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

      for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        await uploadChunk(chunk, i, file.name, totalChunks);

        onProgress(
          {
            percent: Math.round(((CHUNK_SIZE * (i + 1)) / file.size) * 100).toFixed(2),
          },
          file,
        );
      }

      message.success('Upload complete');
      // //@ts-ignore
      onSuccess(null, file);
      return false; // Prevent default upload behavior
    },
  };

  const removeFile = async (id: number, name: string) => {
    axios.delete(`/api/files/${id}`)
      .then(() => {
        notification.success({
          message: `File "${name}" was deleted`,
        });
        getUpdatedFiles();
      })
      .catch((err) => {
        console.error(err);
        getUpdatedFiles();
      });
  }
  
  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'File Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        const name = record.name || '';
        const bgColor = record.access !== 'admin' ? '#ffe58f' : '#d9f7be';
        const url = record?.url?.[0] || '';
        const prepareURL = isHostingCloud ? url : `${appURL}/uploads/${name}`;
  
        return (
          <a href={prepareURL} target='_blank' rel="noreferrer">
            <Typography.Text style={{ backgroundColor: bgColor }}>
              <PaperClipOutlined />
            </Typography.Text> {name}
          </a>
        );
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (_, record) => record?.user?.username || '',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => record?.user?.email || '',
    },
    {
      title: 'County',
      dataIndex: 'county',
      key: 'county',
      render: (_, record) => record?.user?.county || '',
    },
    {
      title: 'Remove',
      dataIndex: 'id',
      key: 'id',
      width: "60px",
      render: (_, record) => {
        return (
          <Button onClick={() => { removeFile(record.id, record.name) }}>
            <DeleteFilled />
          </Button>
        )
      },
    },
  ];

  useEffect(() => {
    getRequest(setFiles);
  }, [])

  useEffect(() => {
    if(user?.isAdmin) {
      getAdminRequest(setAllFiles, selectedUserID);
      getUsersByAdminRequest(setAllUsers);
    }
  }, [user?.isAdmin, selectedUserID])

  const handleCancel = () => {
    setIsModalOpen(false);
    setRewriteFiles([]);
  };

  const handleOk = () => {
    const url = user?.isAdmin && selectedUserID > DEFAULT_USER_VALUE ? `/api/files/rewrite/admin/${selectedUserID}` : '/api/files/rewrite';
    setIsModalOpen(false);

    return axios.post(url, {
      files: rewriteFiles,
    }).then(async () => {
      notification.success({
        message: 'Files was updated.',
        description: rewriteFiles.join(', '),
      });

      setIsModalOpen(false);
      setRewriteFiles([]);

      setTimeout(() => {
        getUpdatedFiles();
      }, 1000);

      setTimeout(() => {
        getUpdatedFiles();
      }, 5000);

      setTimeout(() => {
        getUpdatedFiles();
      }, 10000);
    })
  };

  const Wrapper = styled.section`
    padding: 0 2em;
  `;

  const handleChange = (value: number) => {
    setSelectedUserID(value);
  };

  return (
    <>
      <Page
        showHeader={true}
        child={
          <div>
            <Typography.Title level={4}>Welcome to the Secure File Transfer Portal</Typography.Title>
              <Upload multiple={true} {...props}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            
          </div>
        }
      />
      {
        allUsers?.length > 0 && (
          <Wrapper style={{ margin: "0 25px 25px", padding: 0 }}>
            <Typography.Title level={5}>Select the user to upload the file to:</Typography.Title>
            <Select
              defaultValue={selectedUserID}
              style={{ width: '100%' }}
              onChange={handleChange}
              options={[
                { value: DEFAULT_USER_VALUE, label: 'Default' },
                ...allUsers.map(({id, username, county, email, }) => ({
                  value: id,
                  label: county ? `${username} - ${county} (${email})` : username,
                }))
              ]}
            />
          </Wrapper>
        )
      }
      <Divider />
      {
        files?.length > 0 && (
          <Wrapper>
            <Typography.Title level={4}>Your Files</Typography.Title>
            <Table columns={columns} dataSource={files} rowKey="id" />
          </Wrapper>
        )
      }
      {
        allFiles?.length > 0 && (
          <Wrapper style={{ marginTop: "15px" }}>
            <Typography.Title level={4}>Uploaded All Files</Typography.Title>
            <Table columns={columns} dataSource={allFiles} rowKey="id" />
          </Wrapper>
        )
      }
      <Modal
        title="You already have such files:"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <List
          size="small"
          bordered
          dataSource={rewriteFiles}
          renderItem={(item: string) => <List.Item>{item}</List.Item>}
        />
        <p>Do you want to rewrite them?</p>
      </Modal>
    </>
  );
};