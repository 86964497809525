import React from 'react';
import Page from '../sections/Page';

export default function NotFound() {
  return (
    <Page
      showHeader={true}
      child={
        <div>
          Page not found. Please use the following link to start over:
          <a href="https://portal.broadbandanalyzer.com/home">&nbsp; Home</a>
        </div>
      } />
  );
}